



































































import { isEqual } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins } from 'vue-property-decorator'

import Select from '@/components/_uikit/controls/Select.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import DictionaryModule from '@/store/modules/dictionary'
import SupportModule from '@/store/modules/support'
import { ISupportFilter, ISupportFilterType, TicketShortResource } from '@/store/types'
import { tableFooterOptions } from '@/utils/constants'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    Select,
    TableFooter,
    Tag,
    TextInput,
  },
})
export default class SupportTable extends Mixins(PermissionsMixin) {
  private footerOptions = tableFooterOptions

  private get isManagerView () {
    return this.$route.name === 'manager.support.tickets'
  }

  private get filter () {
    return SupportModule.ticketsFilter
  }

  private set filter (filter: ISupportFilter) {
    if (!isEqual(filter, this.filter) || this.tickets.length === 0) {
      SupportModule.setTicketsFilter(filter)
      this.fetchTickets()
    }
  }

  private get statuses () {
    return DictionaryModule.ticketStatuses
  }

  private hasNewItems(item: TicketShortResource) {
    if (item.hasNewMessages) {
      return 'support__ticket has-new-messages'
    }
    return 'support__ticket'
  }

  private get headers () {
    return [
      { sortable: false, text: '#', value: 'id' },
      { text: 'Автор', value: 'author' },
      { cellClass: 'cell-width-40', text: 'Тема', value: 'theme' },
      { cellClass: 'cell-hidden-md', class: 'cell-hidden-md', text: 'Дата и время создания', value: 'createdAt' },
      { cellClass: 'cell-hidden-md', class: 'cell-hidden-md', text: 'Последний ответ', value: 'lastMessageAt' },
      { text: 'Статус', value: 'status' },
    ]
  }

  private get tickets () {
    return SupportModule.tickets.data
  }

  private get pagination () {
    return SupportModule.tickets.meta
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private mounted () {
    this.$bus.$on('updateTicketsList', this.updateTicketsList)
    if (!this.isManagerView) {
      this.filter = {
        ...this.filter,
        myRequest: 1,
      }
    } else if (this.hasPermission(this.Permission.SUPPORT_ANSWER)) {
      this.filter = {
        ...this.filter,
        myRequest: 0,
      }
    }
  }

  private destroyed() {
    this.$bus.$off('updateTicketsList', this.updateTicketsList as any)
  }

  @Bind
  @Debounce(300)
  private updateTicketsList() {
    SupportModule.fetchTickets(false)
      .catch(this.notifyError)
  }

  @Bind
  @Debounce(300)
  private fetchTickets () {
    SupportModule.fetchTickets()
      .catch(this.notifyError)
  }

  private handleFilter (field: ISupportFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private handleRowClick (ticket: TicketShortResource) {
    this.$router.push({
      name: this.$route.name === 'manager.support.tickets' ? 'manager.support.item' : 'master.support.item',
      params: { ticketID: ticket.id.toString() },
    })
  }

  private handleNewTicket () {
    this.$router.push({ name: this.$route.name === 'manager.support.tickets' ? 'manager.support.new' : 'master.support.new' })
  }
}
